<template>
    <div id="page-associator">
        <div class="display-5 m-3" >Page Manager</div>
        <div class="row m-2">
            <p>Currently only for CINC associated pages</p>
        </div>
        <div v-if="!isPageSelected">

            <div class="row">
                <div class="col-md-6 ms-3">
                    <div class="form-group">
                        <label class="m-2" for="pageSearch">Search Pages:</label>
                        <input id="pageSearch" class="form-control" type="text" v-model="pageSearchInput" placeholder="Search by page name or ID" />
                    </div>
                    
                </div>
            </div>  
            <div class="row" v-if="isSearchedForPage">
                <div class="col-md-6 m-3 overflow-auto" style="max-height: 520px; cursor: pointer;">
                    <ul class="list-group" v-for="(page, index) in filteredPages" :key="page.PageID">
                        <li class="list-group-item" :class="getPageClass(index)" @click="pageClick(page)" style="cursor: pointer">
                            <div class="d-flex w-100 justify-content-between">
                                <h6 class="mb-1">{{ page.PageName }}</h6>
                            </div>
                            <small class="mb-1">{{ page.PageID }}</small>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="isPageSelected">
            <div class="row m-3">
                <div class="list-group col-md-6">
                    <li class="list-group-item">
                        <div class="d-flex w-100 justify-content-between">
                            <h6 class="mb-1">{{ selectedPageName }}</h6>
                            <span @click="clearPage()" class="badge bg-secondary rounded-pill" style="cursor: pointer;">
                                <font-awesome-icon icon="xmark" style="margin: 2px; margin-right: 2px;"></font-awesome-icon>
                            </span>
                        </div>
                        <small>{{ updateRequest.PageID }}</small>
                    </li>
                </div>
            </div>
            <div class="row m-3">
                <div class="col-md-6">
                    <label for="domain">Selected Domain</label>
                    <div class="input-group">
                        <input type="text" disabled v-model="updateRequest.DomainName" id="domain" class="form-control" placeholder="Domain">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" @click="changeDomainClick">Change</button>
                        </div>
                    </div>
                    <small class="form-text text-muted">DDID: {{ updateRequest.DDID }}</small>
                </div>
            </div>
            <div v-if="displayDomainSearch">
                <div class="row m-3">
                    <div class="col-md-6">
                        <label for="domainSearch">Search for CINC domains</label>
                        <div class="input-group">
                            <input type="text" v-model="cincDomainSearchInput" class="form-control" id="domainSearch" placeholder="Search for a CINC domain" />
                            <div class="input-group-append">
                                <button class="btn btn-primary" @click="searchCincPlatforms">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 m-3 overflow-auto" style="max-height: 520px; cursor: pointer;">
                        <ul class="list-group" v-for="(cincDomain, index) in cincDomainSearchResults" :key="cincDomain.Id">
                            <li class="list-group-item" :class="getPageClass(index)" @click="cincDomainClick(cincDomain)" style="cursor: pointer">
                                <div class="d-flex w-100 justify-content-between">
                                    <h6 class="mb-1">{{ cincDomain.DomainName }}</h6>
                                </div>
                                <small class="mb-1">{{ cincDomain.Status }}</small>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row m-3">
                    <div class="col-md-6">
                        <label for="about">About/Bio</label>
                        <textarea style="height: 150px;" v-model="updateRequest.About" class="form-control" id="about" placeholder="About/Bio" />
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-md-6">
                        <label for="Phone">Phone</label>
                        <input type="text" v-model="updateRequest.Phone" class="form-control" id="phone" placeholder="Phone Number" />
                        <small class="form-text text-muted">Should lead with the country code and only be numerical characters</small>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-md-6">
                        <label for="website">Website URL</label>
                        <input type="text" v-model="updateRequest.Website" class="form-control" id="website" placeholder="https://www.cincdomain.com" />
                        <small class="form-text text-muted">Should lead with https:// or http://</small>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-md-6">
                        <button class="btn btn-primary mt-3" @click="updateManagedPage()">Update</button>
                        <button class="btn btn-secondary mt-3" @click="clearPage()">Cancel</button>
                    </div>
                </div>
            </div>
            <div v-if="displaySuccess">
                <div class="row mt-4">
                    <div class="col-sm-8 alert alert-success">
                        <p class="display-1 text-success text-center"><font-awesome-icon :icon="['far', 'check-circle']"/></p>
                        <h3 class="text-center text-success m-2">Success!</h3>
                        <p class="text-center m-2">The page has been updated in Facebook and in our CINC system</p>
                    </div>
                </div>
            </div>
            <div v-if=displayPartialSuccess>
                <div class="row mt-4">
                    <div class="col-sm-8 alert alert-warning">
                        <p class="display-1 text-warning text-center"><font-awesome-icon :icon="['far', 'check-circle']"/></p>
                        <h3 class="text-center text-warning m-2">Partial Success</h3>
                        <p class="text-center m-2">{{ updateResponseMessage }}</p>
                    </div>
                </div>
            </div>
            <div v-if="displayError">
                <div class="row mt-4">
                    <div class="col-sm-8 alert alert-danger">
                        <p class="display-1 text-danger text-center"><font-awesome-icon :icon="['far', 'xmark']"/></p>
                        <h3 class="text-center text-danger m-2">Failure</h3>
                        <p class="text-center m-2">{{ updateResponseMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ManagedPage } from '../../../models/ManagedPage';
import { CincPlatform } from '../../../models/CincPlatform';
import { UpdatePageRequest } from '../../../models/request/UpdatePageRequest';
import { Company } from '../../../enums/Company';

@Component
export default class PageManager extends Vue {
    
    displaySuccess: boolean = false;
    displayPartialSuccess: boolean = false;
    displayError: boolean = false;
    updateResponseMessage: string = "";
    pageSearchInput: string = "";
    managedPages: ManagedPage[] = new Array<ManagedPage>();
    updateRequest: UpdatePageRequest = new UpdatePageRequest();
    selectedDomainDDID: string = "";
    selectedPageName: string = "";

    cincDomainSearchInput : string = "";
    cincDomainSearchResults: CincPlatform[] = new Array<CincPlatform>();

    get filteredPages() : ManagedPage[] {
        if(this.pageSearchInput === "") {
            return this.managedPages;
        }
        return this.managedPages.filter((page : ManagedPage) => page.PageName.toLowerCase().includes(this.pageSearchInput.toLowerCase()) || page.PageID.includes(this.pageSearchInput));
    }

    get isSearchedForPage() : boolean {
        return this.pageSearchInput.length > 0;
    }

    get isPageSelected() : boolean {
        return this.updateRequest.PageID !== "";
    }

    get displayDomainSearch() : boolean {
        return this.updateRequest?.DDID !== "" && this.selectedDomainDDID === "";
    }

    getPageClass(index: number) {
        return index % 2 == 0 ? '':'list-group-item-light';
    }

    async created() {
        this.managedPages = await this.$store.dispatch('managedPage/getAllManagedPages');
    }

    async pageClick(page: ManagedPage) {
        console.log(page);
        this.selectedPageName = page.PageName;
        this.updateRequest.PageID = page.PageID;
        this.updateRequest.DDID = page.DDID;
        this.updateRequest.DomainName = page.DomainName;
        this.updateRequest.About = page.About;
        this.updateRequest.Phone = page.Phone;
        this.updateRequest.Website = page.Website;

        if(page.DomainName === undefined || page.DomainName === null || page.DomainName === "") {
            let cincPlatform : CincPlatform = await this.$store.dispatch('cincPlatform/getCincPlatform', page.DDID);
            if(cincPlatform !== null && cincPlatform !== undefined) {
                this.updateRequest.DomainName = cincPlatform.DomainName;
                if(page.Website === undefined || page.Website === null || page.Website === "") {
                    this.updateRequest.Website = `https://www.${cincPlatform.DomainName}`;
                }
            }
        }
        this.selectedDomainDDID = page.DDID;
    }

    async cincDomainClick(cincDomain: CincPlatform) {
        this.updateRequest.DomainName = cincDomain.DomainName;
        this.updateRequest.DDID = cincDomain.Id;
        this.selectedDomainDDID = cincDomain.Id;
        this.updateRequest.Website = `https://www.${cincDomain.DomainName}`;

        let countryCode = cincDomain.Country === "US" || "CA" ? "1" : cincDomain.Country === "MX" ? "52" : "";
        if(!(cincDomain.ContactPhone === null || cincDomain.ContactPhone === undefined))
            this.updateRequest.Phone =  `${countryCode}${cincDomain.ContactPhone.replace(/\D/g,'')}`;

        this.updateRequest.About = `${cincDomain.CompanyName}\r\n${cincDomain.ContactFirstName} ${cincDomain.ContactLastName}\r\n${cincDomain.ContactAddress}\r\n${cincDomain.ContactCity}, ${cincDomain.ContactState} ${cincDomain.ContactZip}\r\n${cincDomain.ContactPhone}`;
    }

    async changeDomainClick() {
        this.selectedDomainDDID = "";
    }

    clearPage() {
        this.updateRequest = new UpdatePageRequest();
        this.displaySuccess = false;
        this.displayPartialSuccess = false;
        this.displayError = false;
    }

    async searchCincPlatforms() {
        this.cincDomainSearchResults = await this.$store.dispatch('cincPlatform/getCincPlatforms', this.cincDomainSearchInput)
    }

    async updateManagedPage() {
        this.updateRequest.Company = Company.CINC;
        await this.$store.dispatch('managedPage/updateManagedPage', this.updateRequest)
        .then((response) => {
            
            if(response.status === 200) {
                this.displaySuccess = true;
                this.displayPartialSuccess = false;
                this.$data.updateResponseMessage = "";
            }
            if(response.status === 206) {
                this.displayPartialSuccess = true;
                this.displaySuccess = false;
                this.updateResponseMessage = response.data;
            }
        })
        .catch((error) => {
            this.displayError = true;
            this.updateResponseMessage = `An error occurred while updating the page. ${error}`;
        });
    }
}

</script>